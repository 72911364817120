<template>
  <v-card flat>
    <v-card-text>
      <div class="d-flex justify-space-between">
        <div class="d-flex">
          <!-- ปุ่มเลือกสินค้า -->
          <v-select dense outlined hide-details label="ช่วงเวลา" :items="year" class="me-2"></v-select>
          <date-picker v-model="time3" range placeholder="เฉพาะวันที่" class="picker-res"></date-picker>
        </div>
        <v-btn color="primary" v-bind="attrs" v-on="on" class="me-2 mg-res">
          <v-icon class="me-2">
            {{ icons.mdiCheck }}
          </v-icon>
          รันรีพอร์ต
        </v-btn>
      </div>
      <div class="mt-4 d-flex align-center filter">
        <h3 class="me-2">ตัวกรอง</h3>
        <v-text-field
          label="ค้นหาชื่อลูกค้า"
          type="text"
          outlined
          dense
          hide-details
          placeholder="ค้นหาชื่อลูกค้า"
        ></v-text-field>
      </div>
    </v-card-text>

    <!-- Content -->
    <v-card-text>
      <hr class="mt-5 mb-5" />
      <v-row>
        <v-col sm="3" md="3" cols="6">
          <div class="contentClick" @click="clickChange(1)">
            <h3>สินค้าทำกำไรทั้งบริษัท</h3>
            <p class="mb-0">สินค้าที่ทำกำไรมากสุดให้แก่บริษัท</p>
          </div>
        </v-col>
        <v-col sm="3" md="3" cols="6">
          <div class="contentClick" @click="clickChange(2)">
            <h3>สินค้าทำกำไรต่อหน่วย</h3>
            <p class="mb-0">สินค้าทำกำไรมากสุดต่อหน่วย</p>
          </div>
        </v-col>
        <v-col sm="3" md="3" cols="6">
          <div class="contentClick" @click="clickChange(3)">
            <h3>สินค้าขายดี</h3>
            <p class="mb-0">สินค้าที่สร้างยอดขายได้มากที่สุด</p>
          </div>
        </v-col>
        <v-col sm="3" md="3" cols="6">
          <div class="contentClick" @click="clickChange(4)">
            <h3>สินค้าขายช้า</h3>
            <p class="mb-0">สินค้าที่อยู่ในสต๊อกเป็นเวลานาน</p>
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <!-- content ProfitAll -->
    <v-card-text v-if="contentIncome">
      <hr class="mb-5" />
      <div class="d-flex justify-space-between align-center">
        <div>
          <h3>สินค้าทำกำไรทั้งบริษัท</h3>
          <p class="mb-0">สินค้าที่ทำกำไรมากสุดให้แก่บริษัท</p>
        </div>
        <p class="mb-0 font-weight-bold">
          รายการสินค้า <span>{{ countProduct }} รายการ</span>
        </p>
      </div>
      <hr class="mt-5 mb-5" />
      <ProfitProduct />
      <hr class="mt-5 mb-5" />
      <div class="d-flex justify-space-between align-center">
        <div>
          <h3>รายการสินค้าทำกำไรทั้งบริษัท</h3>
          <span class="font-weight-bold">{{ name }}</span>
          <p class="mb-0">1 ม.ค. {{ new Date().getFullYear() }} - 31 ธ.ค. {{ new Date().getFullYear() }}</p>
        </div>
        <div>
          <v-text-field label="ค้นหา" type="text" outlined dense hide-details placeholder="ค้นหา"></v-text-field>
        </div>
      </div>
      <hr class="mt-5 mb-5" />
      <ProfitListProduct />
    </v-card-text>
    <!-- end content ProfitAll -->

    <!-- content ProfitUnit -->
    <v-card-text v-if="contentProfit">
      <hr class="mb-5" />
      <div class="d-flex justify-space-between align-center">
        <div>
          <h3>สินค้าทำกำไรต่อหน่วย</h3>
          <p class="mb-0">สินค้าที่ทำกำไรมากสุดต่อหน่วย</p>
        </div>
        <p class="mb-0 font-weight-bold">
          รายการสินค้า <span>{{ countProduct }} รายการ</span>
        </p>
      </div>
      <hr class="mt-5 mb-5" />
      <ProductUnit />
      <hr class="mt-5 mb-5" />
      <div class="d-flex justify-space-between align-center">
        <div>
          <h3>รายการสินค้าทำกำไรต่อหน่วย</h3>
          <span class="font-weight-bold">{{ name }}</span>
          <p class="mb-0">1 ม.ค. {{ new Date().getFullYear() }} - 31 ธ.ค. {{ new Date().getFullYear() }}</p>
        </div>
        <div>
          <v-text-field label="ค้นหา" type="text" outlined dense hide-details placeholder="ค้นหา"></v-text-field>
        </div>
      </div>
      <hr class="mt-5 mb-5" />
      <ProductUnitList />
    </v-card-text>
    <!-- end content ProfitUnit -->

    <!-- content Best Sale -->
    <v-card-text v-if="contentLocation">
      <hr class="mb-5" />
      <div class="d-flex justify-space-between align-center">
        <div>
          <h3>สินค้าขายดี</h3>
          <p class="mb-0">สินค้าที่สร้างยอดขายได้มากที่สุด</p>
        </div>
        <p class="mb-0 font-weight-bold">
          รายการสินค้า <span>{{ countProduct }} รายการ</span>
        </p>
      </div>
      <hr class="mt-5 mb-5" />
      <BestSale />
      <hr class="mt-5 mb-5" />
      <div class="d-flex justify-space-between align-center">
        <div>
          <h3>รายการสินค้าขายดี</h3>
          <span class="font-weight-bold">{{ name }}</span>
          <p class="mb-0">1 ม.ค. {{ new Date().getFullYear() }} - 31 ธ.ค. {{ new Date().getFullYear() }}</p>
        </div>
        <div>
          <v-text-field label="ค้นหา" type="text" outlined dense hide-details placeholder="ค้นหา"></v-text-field>
        </div>
      </div>
      <hr class="mt-5 mb-5" />
      <BestSaleList />
    </v-card-text>
    <!-- end content Best Sale -->

    <!-- content low sell -->
    <v-card-text v-if="contentCredit">
      <hr class="mb-5" />
      <div class="d-flex justify-space-between align-center">
        <div>
          <h3>สินค้าขายช้า</h3>
          <p class="mb-0">สินค้าที่อยู่ในสต๊อกเป็นเวลานาน</p>
        </div>
      </div>
      <hr class="mt-5 mb-5" />
      <LowSell />
      <hr class="mt-5 mb-5" />
      <div class="d-flex justify-space-between align-center">
        <div>
          <h3>รายการสินค้าขายช้า</h3>
          <span class="font-weight-bold">{{ name }}</span>
          <p class="mb-0">1 ม.ค. {{ new Date().getFullYear() }} - 31 ธ.ค. {{ new Date().getFullYear() }}</p>
        </div>
      </div>
      <hr class="mt-5 mb-5" />
      <LowSellList />
    </v-card-text>
    <!-- end content low sell -->
    <!-- End Content  -->
  </v-card>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { mdiCheck, mdiMenuUp, mdiMenuDown } from '@mdi/js'
import ProfitProduct from './product/ProfitProduct.vue'
import ProfitListProduct from './product/ProfitListProduct.vue'
import ProductUnit from './product/ProductUnit.vue'
import ProductUnitList from './product/ProductUnitList.vue'
import BestSale from './product/BestSale.vue'
import BestSaleList from './product/BestSaleList.vue'
import LowSell from './product/LowSell.vue'
import LowSellList from './product/LowSellList.vue'

export default {
  components: {
    DatePicker,
    ProfitListProduct,
    ProfitProduct,
    ProductUnit,
    ProductUnitList,
    BestSale,
    BestSaleList,
    LowSell,
    LowSellList,
  },
  setup() {
    const year = [
      'เลือกช่วงเวลา',
      'กำหนดเอง',
      'เดือนนี้',
      'ไตรมาสนี้',
      ' ปีปัจจุบัน',
      'ปีก่อนหน้า',
      'เดือนนี้ถึงวันนี้',
      'ไตรมาสนี้ถึงวันนี้',
      'ปีนี้ถึงวันนี้',
      'ปีงบประมาณปัจจุบัน',
      'ปีงบประมาณก่อนหน้า',
      '12 เดือนย้อนหลัง',
    ]
    const dataMock = {
      listSell: {
        total: 500000,
        average: 2,
      },
      sales: {
        total: 1000000,
        average: 10,
      },
      profit: {
        total: 400000,
        average: -1,
      },
    }

    return {
      year,
      dataMock,
      icons: {
        mdiCheck,
        mdiMenuUp,
        mdiMenuDown,
      },
    }
  },
  data() {
    return {
      time1: null,
      time2: null,
      time3: null,
      dataOpenUser: false,
      name: 'Case',
      listSell: true,
      sales: false,
      profitAll: false,
      countProduct: 2,
      contentIncome: true,
      contentProfit: false,
      contentLocation: false,
      contentCredit: false,
    }
  },

  methods: {
    switchOpenUser() {
      this.dataOpenUser = !this.dataOpenUser
    },
    clickWapper(id) {
      if (id === 2) {
        this.listSell = false
        this.sales = true
        this.profitAll = false
      } else if (id === 3) {
        this.listSell = false
        this.sales = false
        this.profitAll = true
      } else {
        this.listSell = true
        this.sales = false
        this.profitAll = false
      }
    },
    clickChange(id) {
      if (id === 2) {
        this.contentIncome = false
        this.contentProfit = true
        this.contentLocation = false
        this.contentCredit = false
      } else if (id === 3) {
        this.contentIncome = false
        this.contentProfit = false
        this.contentLocation = true
        this.contentCredit = false
      } else if (id === 4) {
        this.contentIncome = false
        this.contentProfit = false
        this.contentLocation = false
        this.contentCredit = true
      } else {
        this.contentIncome = true
        this.contentProfit = false
        this.contentLocation = false
        this.contentCredit = false
      }
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
.warpper_show {
  border-left: 1px solid #e5e5e5;
  transition: all 0.1s ease-in;
  cursor: pointer;
  max-height: 7rem;
  /* background-color: aquamarine; */
}
.warpper_show:hover {
  border-bottom: 2px solid #8dbc8a;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.upColor {
  color: #8dbc8a;
}
.downColor {
  color: red;
}
.contentClick {
  border: #e5e5e5 1px solid;
  border-radius: 4px;
  margin: 1rem 0;
  padding: 10px 10px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}
.contentClick:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  transform: translateZ(2);
}
.filter {
  width: 30%;
}
</style>
