<template>
  <Sell />
</template>

<script>
import Sell from '../conclude-data/Sell.vue'

export default {
  components: {
    Sell,
  },
}
</script>

<style></style>
