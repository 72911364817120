<template>
  <v-card id="account-setting-card">
    <!-- tabs -->
    <v-tabs v-model="tab" show-arrows>
      <v-tab v-for="tab in tabs" :key="tab.key">
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <SellConcluse />
      </v-tab-item>

      <v-tab-item>
        <SellCustomer />
      </v-tab-item>

      <v-tab-item>
        <SellProduct />
      </v-tab-item>

      <v-tab-item>
        <SellMonthlySales />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'

// demos
import SellConcluse from './Sell_Concluse.vue'
import SellCustomer from './Sell_Customer.vue'
import SellProduct from './Sell_product.vue'
import SellMonthlySales from './Sell_MonthlySales.vue'

export default {
  components: {
    SellConcluse,
    SellCustomer,
    SellProduct,
    SellMonthlySales,
  },
  setup() {
    const tab = ref('')

    // tabs
    const tabs = [
      { title: 'สรุปยอดขาย', key: 'conclusePage' },
      { title: 'ลูกค้า', icon: 'customerPage' },
      { title: 'สินค้า', icon: 'productPage' },
      { title: 'ยอดขายรายเดือน', icon: 'monthlySalesPage' },
    ]

    // account settings data
    const accountSettingData = {
      account: {
        avatarImg: require('@/assets/images/avatars/1.png'),
        username: 'whatyouwantshop',
        name: 'whatyouwantshop',
        email: 'whatyouwantshop@example.com',
        role: 'Admin',
        status: 'Active',
        company: 'Google.inc',
      },
      information: {
        bio: '',
        birthday: 'February 22, 1995',
        phone: '09461075555',
        website: 'https://whatyouwantshop.com/',
        country: 'USA',
        languages: ['English', 'Spanish'],
        gender: 'male',
      },
    }

    return {
      tab,
      tabs,
      accountSettingData,
    }
  },
  data: () => ({
    items: [
      {
        text: 'สินค้า',
        disabled: false,
        href: 'product',
      },
      {
        text: 'สต๊อก',
        disabled: true,
        href: 'stock',
      },
    ],
  }),
}
</script>
