<template>
  <v-card flat>
    <v-card-text>
      <div class="d-flex justify-space-between">
        <div class="d-flex">
          <!-- ปุ่มเลือกสินค้า -->
          <v-select dense outlined hide-details label="ช่วงเวลา" :items="year" class="me-2"></v-select>
          <date-picker v-model="time3" range placeholder="เฉพาะวันที่" class="picker-res"></date-picker>
        </div>
        <v-btn color="primary" v-bind="attrs" v-on="on" class="me-2 mg-res">
          <v-icon class="me-2">
            {{ icons.mdiCheck }}
          </v-icon>
          รันรีพอร์ต
        </v-btn>
      </div>
      <hr class="mt-5 mb-5" />
      <!-- Content 1 -->
      <h2 class="text-center">ภาพรวมการขายสินค้า</h2>
      <p class="text-center mb-6">สรุปรายการสินค้าที่ทำยอดขาย และสร้างกำไรให้บริษํท</p>
      <v-row>
        <v-col md="4" sm="4" cols="12" class="warpper_show" @click="clickWapper(1)">
          <p class="mb-0">รายการขาย</p>
          <h3>{{ dataMock.listSell.total }}</h3>
          <div class="d-flex justify-space-between">
            <p>รายการที่ขายไปทั้งหมด</p>
            <p v-if="dataMock.listSell.average > 0" class="upColor">
              <v-icon class="upColor">
                {{ icons.mdiMenuUp }}
              </v-icon>
              {{ dataMock.listSell.average }} %
            </p>
            <p v-if="dataMock.listSell.average < 0" class="downColor">
              <v-icon class="downColor">
                {{ icons.mdiMenuDown }}
              </v-icon>
              {{ dataMock.listSell.average }} %
            </p>
          </div>
        </v-col>
        <v-col md="4" sm="4" cols="12" class="warpper_show" @click="clickWapper(2)">
          <p class="mb-0">ยอดขาย</p>
          <h3>{{ dataMock.sales.total }}</h3>
          <div class="d-flex justify-space-between">
            <p class="mb-0">ยอดขายรวมที่ขายได้</p>
            <p v-if="dataMock.sales.average > 0" class="upColor">
              <v-icon class="upColor">
                {{ icons.mdiMenuUp }}
              </v-icon>
              {{ dataMock.sales.average }} %
            </p>
            <p v-if="dataMock.sales.average < 0" class="downColor">
              <v-icon class="downColor">
                {{ icons.mdiMenuDown }}
              </v-icon>
              {{ dataMock.sales.average }} %
            </p>
          </div>
        </v-col>
        <v-col md="4" sm="4" cols="12" class="warpper_show" @click="clickWapper(3)">
          <p class="mb-0">กำไรทั้งหมด</p>
          <h3>{{ dataMock.profit.total }}</h3>
          <div class="d-flex justify-space-between">
            <p class="mb-0">กำไรรวมที่ขายได้</p>
            <p v-if="dataMock.profit.average > 0" class="upColor">
              <v-icon class="upColor">
                {{ icons.mdiMenuUp }}
              </v-icon>
              {{ dataMock.profit.average }} %
            </p>
            <p v-if="dataMock.profit.average < 0" class="downColor">
              <v-icon class="downColor">
                {{ icons.mdiMenuDown }}
              </v-icon>
              {{ dataMock.profit.average }} %
            </p>
          </div>
        </v-col>
      </v-row>
      <div v-if="listSell" class="mt-5">
        <ConCludeList />
      </div>
      <div v-if="sales" class="mt-5">
        <ConCludeSales />
      </div>
      <div v-if="profitAll" class="mt-5">
        <ConCludeProfit />
      </div>
    </v-card-text>
    <!-- End Content 1 -->

    <!-- Content 2 -->
    <v-card-text>
      <hr class="mt-5 mb-5" />
      <header class="d-flex justify-space-between align-center">
        <div>
          <h2>ยอดขายลูกค้า</h2>
          <p class="mb-0">ลูกค้าที่ทำการซื้อขาย และสร้างกำไรให้บริษัท สรุปรายการขาย ยอดขาย และผลกำไร</p>
        </div>
        <v-btn color="primary" :to="{ name: 'pages-customer' }">ดูรายงานการขายลูกค้า</v-btn>
      </header>
      <section class="mt-4">
        <v-row>
          <!-- content left -->
          <v-col md="4" sm="4" cols="12">
            <div class="slected_content" @click="clickShowcontent(1)">
              <p class="mb-0">ลูกค้ารายใหญ่</p>
              <span>ลูกค้าที่ทำการซื้อขาย และสร้างผลกำไรให้บริษัทเป็นอันดับต้น ๆ</span>
            </div>
            <div class="slected_content" @click="clickShowcontent(2)">
              <p class="mb-0">ลูกค้าทั่วไป</p>
              <span>ลูกค้าที่ทำการซื้อขายกันบริษัทเป็นประจำ</span>
            </div>
            <div class="slected_content" @click="clickShowcontent(3)">
              <p class="mb-0">ลูกค้ารายย่อย</p>
              <span>ลูกค้าที่ทำการซื้อขายกับบริษัท เป็นครั้งคราวหรือลูกค้าใหม่</span>
            </div>
          </v-col>
          <!-- content right -->
          <v-col v-if="ratingCustomerBig" md="8" sm="8" cols="12">
            <SalesBigCustomer />
          </v-col>
          <v-col v-if="ratingCustomerDefault" md="8" sm="8" cols="12">
            <SalesCustomer />
          </v-col>
          <v-col v-if="ratingCustomerMini" md="8" sm="8" cols="12">
            <SalesRetailCustomer />
          </v-col>
        </v-row>
      </section>
    </v-card-text>
    <!-- End Content 2 -->

    <!-- Content 3 -->
    <v-card-text>
      <hr class="mt-5 mb-5" />
      <header class="d-flex justify-space-between align-center">
        <div>
          <h2>ยอดขายสินค้า</h2>
          <p class="mb-0">สินค้าที่ทำการซื้อขาย และสร้างกำไรให้บริษัท สรุปรายการขาย ยอดขาย และผลกำไร</p>
        </div>
        <v-btn color="primary" :to="{ name: 'pages-customer' }">ดูรายงานการขายสินค้า</v-btn>
      </header>
      <section class="mt-4">
        <v-row>
          <!-- content left -->
          <v-col md="4" sm="4" cols="12">
            <div class="slected_content" @click="clickShowProduct(1)">
              <p class="mb-0">สินค้าทำกำไร</p>
              <span>ลูกค้าที่ทำการซื้อขาย และสร้างผลกำไรให้บริษัทเป็นอันดับต้น ๆ</span>
            </div>
            <div class="slected_content" @click="clickShowProduct(2)">
              <p class="mb-0">สินค้าขายดี</p>
              <span>สินค้าที่มีการซื้อขาย และมียอดขายเป็นจำนวนมาก</span>
            </div>
          </v-col>
          <!-- content right -->
          <v-col v-if="productMakeProfit" md="8" sm="8" cols="12">
            <ProfitableProduct />
          </v-col>
          <v-col v-if="productBest" md="8" sm="8" cols="12">
            <BestsellerProduct />
          </v-col>
        </v-row>
      </section>
    </v-card-text>
    <!-- End Content 3 -->
  </v-card>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { mdiCheck, mdiMenuUp, mdiMenuDown } from '@mdi/js'
import ConCludeList from './conclude-sales/conclude_lists.vue'
import ConCludeSales from './conclude-sales/conclude_sales.vue'
import ConCludeProfit from './conclude-sales/conclude_profit.vue'
import SalesBigCustomer from './conclude-sales/Sales_bigCustomer.vue'
import SalesCustomer from './conclude-sales/Sales_customer.vue'
import SalesRetailCustomer from './conclude-sales/Sales_retailCustomer.vue'
import ProfitableProduct from './conclude-sales/Profitable_product.vue'
import BestsellerProduct from './conclude-sales/Bestseller_product.vue'

export default {
  components: {
    DatePicker,
    ConCludeList,
    ConCludeSales,
    ConCludeProfit,
    SalesBigCustomer,
    SalesCustomer,
    SalesRetailCustomer,
    ProfitableProduct,
    BestsellerProduct,
  },
  setup() {
    const year = [
      'เลือกช่วงเวลา',
      'กำหนดเอง',
      'เดือนนี้',
      'ไตรมาสนี้',
      ' ปีปัจจุบัน',
      'ปีก่อนหน้า',
      'เดือนนี้ถึงวันนี้',
      'ไตรมาสนี้ถึงวันนี้',
      'ปีนี้ถึงวันนี้',
      'ปีงบประมาณปัจจุบัน',
      'ปีงบประมาณก่อนหน้า',
      '12 เดือนย้อนหลัง',
    ]
    const dataMock = {
      listSell: {
        total: 500000,
        average: 2,
      },
      sales: {
        total: 1000000,
        average: 10,
      },
      profit: {
        total: 400000,
        average: -1,
      },
    }

    return {
      year,
      dataMock,
      icons: {
        mdiCheck,
        mdiMenuUp,
        mdiMenuDown,
      },
    }
  },
  data() {
    return {
      time1: null,
      time2: null,
      time3: null,
      dataOpenUser: false,
      name: 'Case',
      listSell: true,
      sales: false,
      profitAll: false,
      ratingCustomerBig: true,
      ratingCustomerDefault: false,
      ratingCustomerMini: false,
      productMakeProfit: true,
      productBest: false,
    }
  },

  methods: {
    switchOpenUser() {
      this.dataOpenUser = !this.dataOpenUser
    },
    clickWapper(id) {
      if (id === 2) {
        this.listSell = false
        this.sales = true
        this.profitAll = false
      } else if (id === 3) {
        this.listSell = false
        this.sales = false
        this.profitAll = true
      } else {
        this.listSell = true
        this.sales = false
        this.profitAll = false
      }
    },
    clickShowcontent(id) {
      if (id === 2) {
        this.ratingCustomerBig = false
        this.ratingCustomerDefault = true
        this.ratingCustomerMini = false
      } else if (id === 3) {
        this.ratingCustomerBig = false
        this.ratingCustomerDefault = false
        this.ratingCustomerMini = true
      } else {
        this.ratingCustomerBig = true
        this.ratingCustomerDefault = false
        this.ratingCustomerMini = false
      }
    },
    clickShowProduct(id) {
      if (id === 2) {
        this.productMakeProfit = false
        this.productBest = true
      } else {
        this.productMakeProfit = true
        this.productBest = false
      }
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
.warpper_show {
  border-left: 1px solid #e5e5e5;
  transition: all 0.1s ease-in;
  cursor: pointer;
  max-height: 7rem;
  /* background-color: aquamarine; */
}
.warpper_show:hover {
  border-bottom: 2px solid #8dbc8a;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.upColor {
  color: #8dbc8a;
}
.downColor {
  color: red;
}
.slected_content {
  border: #e5e5e5 1px solid;
  border-radius: 4px;
  margin: 1rem 0;
  padding: 10px 10px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}
.slected_content:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  transform: translateZ(2);
}
.slected_content p {
  font-size: 18px;
  font-weight: 700;
}
</style>
